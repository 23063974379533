import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import PageHeaderLayout from "library/Layouts/PageHeaderLayout";
import SEO from "library/Molecules/SEO";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetSelectedInvoice, retrieveInvoices, retrieveInvoicesStatus } from "store/features/invoice.store";
import { fetchInvoicesRequest } from "store/requests/invoice.requests";
import InvoiceDetails from "./components/InvoiceDetails/InvoiceDetails";
import InvoiceDownloadButton from "./components/InvoiceDetails/InvoiceDownloadButton/InvoiceDownloadButton";
import InvoiceList from "./components/InvoiceList/InvoiceList";
import InvocesPageLayout from "./layouts/InvocesPageLayout/InvocesPageLayout";
import InvoiceDetailsDrawer from "./modals/InvoiceDetailsDrawer/InvoiceDetailsDrawer";

const Invoices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { idle: invoiceListIdleStatus, loading: invoiceListLoading } = useSelector(retrieveInvoicesStatus);
  const invoices = useSelector(retrieveInvoices);

  const isInvoiceListEmpty = useMemo(() => {
    return !invoiceListLoading && isEmpty(invoices);
  }, [invoices, invoiceListLoading]);

  const onMoveToNewOrder = useCallback(() => navigate("/new-order"), [navigate]);

  const handleDeselectInvoice = useCallback(() => dispatch(resetSelectedInvoice()), [dispatch]);

  useEffect(() => {
    if (!invoiceListIdleStatus) return;
    dispatch(fetchInvoicesRequest());
  }, [invoiceListIdleStatus, dispatch]);

  useEffect(() => {
    return () => dispatch(resetSelectedInvoice());
  }, [dispatch]);

  return (
    <InvocesPageLayout
      headerSection={
        <PageHeaderLayout
          contentClassName="flex-column"
          title={t("invoices.pageTitle")}
          subtitle={t("invoices.pageDescription", isInvoiceListEmpty && { context: "empty" })}
          headerControls={
            isInvoiceListEmpty && (
              <PrimaryButton className="w-100" label={t("common.buttons.newOrderBtn")} onClick={onMoveToNewOrder} />
            )
          }
        />
      }
      listSection={<InvoiceList />}
      modals={
        <InvoiceDetailsDrawer onClose={handleDeselectInvoice} controlsSection={<InvoiceDownloadButton />}>
          <InvoiceDetails onClose={handleDeselectInvoice} />
        </InvoiceDetailsDrawer>
      }
    >
      <SEO title={t("seo.helmetTitle", { name: t("seo.pages.invoices") })} />
    </InvocesPageLayout>
  );
};

export default Invoices;
