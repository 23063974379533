import { createSlice } from "@reduxjs/toolkit";
import { fetchInvoiceDownloadLinkRequest, fetchInvoicesRequest } from "../requests/invoice.requests";

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    invoices: [],
    pagination: { total_pages: 1, active_page: 1 },
    selectedInvoice: null,
    status: "idle",
    invoiceStatus: "idle",
  },
  reducers: {
    setSelectedInvoice: (state, action) => {
      state.selectedInvoice = action.payload;
    },
    resetSelectedInvoice: (state) => {
      state.selectedInvoice = null;
    },
  },
  extraReducers: {
    [fetchInvoicesRequest.pending]: (state) => {
      state.status = "loading";
    },
    [fetchInvoicesRequest.fulfilled]: (state, { payload: { data } }) => {
      state.status = "succeeded";
      state.invoices = data.invoices;
      state.pagination = data.pagination;
    },
    [fetchInvoicesRequest.rejected]: (state) => {
      state.status = "failed";
    },
    [fetchInvoiceDownloadLinkRequest.pending]: (state) => {
      state.invoiceStatus = "loading";
    },
    [fetchInvoiceDownloadLinkRequest.fulfilled]: (state) => {
      state.invoiceStatus = "succeeded";
    },
    [fetchInvoiceDownloadLinkRequest.rejected]: (state) => {
      state.invoiceStatus = "failed";
    },
  },
});

export const { setSelectedInvoice, resetSelectedInvoice } = invoiceSlice.actions;

export const retrieveInvoices = (state) => state.invoice.invoices;

export const retrieveInvoicesPagination = (state) => state.invoice.pagination;

export const retrieveSelectedInvoice = (state) => {
  return state.invoice?.invoices?.find((invoice) => invoice.id === state.invoice?.selectedInvoice) || {};
};

export const retrieveInvoicesStatus = (state) => {
  const { status } = state.invoice;
  return { idle: status === "idle", oading: status === "loading" };
};
export const retrieveDownloadLinkStatus = (state) => {
  const { invoiceStatus } = state.invoice;
  return { loading: invoiceStatus === "loading" };
};

export default invoiceSlice.reducer;
