import { useFormikContext } from "formik";
import Select from "library/Atoms/Select";
import FilterLayout from "library/Layouts/InputLayout/FilterLayout";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { prettifyStatusLabel } from "utility/helpers/general";
import commonStyles from "utility/ui/common";
import { ORDER_STATUSES_LIST } from "../../../../../../constants";

const OrderSelectFilter = () => {
  const { t } = useTranslation();

  const {
    values: { status = "all" },
    setFieldValue,
  } = useFormikContext();

  const statusFilterOptions = useMemo(() => {
    return [
      { label: t("dashboard.forms.filtersForm.filterPlaceholder"), value: "all" },
      ...ORDER_STATUSES_LIST.map((value) => {
        const label = t([`utils.deliveryStatuses.${value}`, prettifyStatusLabel(value)]);
        return { label, value };
      }),
    ];
  }, [t]);

  const handleChangeStatus = useCallback(
    (name, value) => {
      if (status === value) return;
      setFieldValue(name, value);
    },
    [status, setFieldValue]
  );

  return (
    <FilterLayout label={`${t("dashboard.forms.filtersForm.filterLabel")}:`}>
      <Select
        id="order-status-filter"
        name="status"
        selectClassName={commonStyles.filterSelect}
        value={status}
        options={statusFilterOptions}
        onChange={handleChangeStatus}
        variant="standard"
      />
    </FilterLayout>
  );
};

export default OrderSelectFilter;
