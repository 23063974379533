import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import {
  createContactRequest,
  logInRequest,
  reacceptContactTemrsRequest,
  resetPasswordRequest,
  signOutRequest,
  updateContactRequest,
} from "../requests/contact.requests";
import { fetchInvoiceDownloadLinkRequest, fetchInvoicesRequest } from "../requests/invoice.requests";
import {
  createNewAddressRequest,
  deleteAddressRequest,
  deleteSavedOrderRequest,
  editAddressRequest,
  editSavedOrderRequest,
  fetchAddressesRequest,
  fetchSavedOrdersRequest,
  saveOrderRequest,
} from "../requests/library.requests";
import { calculateOrderPriceRequest, createNewOrderRequest, parseCsvQuoteRequest } from "../requests/newOrder.requests";
import { fetchFilteredOrdersRequest, fetchOrdersRequest } from "../requests/order.requests";

export const addSuccessMessage = (state, { payload: { message } }) => {
  state.successMessages = [...state.successMessages, { key: message, id: uuidv4() }];
};
export const addErrorMessage = (state, { payload }) => {
  const key = payload?.response?.data?.message || payload.message;
  state.errorMessages = [...state.errorMessages, { key, id: uuidv4() }];
};

export const toastSlice = createSlice({
  name: "toast",
  initialState: {
    successMessages: [],
    errorMessages: [],
  },
  reducers: {
    removeSuccessMessage: (state, { payload }) => {
      state.successMessages = state.successMessages.filter(({ id }) => id !== payload);
    },
    removeErrorMessage: (state, { payload }) => {
      state.errorMessages = state.errorMessages.filter(({ id }) => id !== payload);
    },
  },
  extraReducers: {
    [fetchOrdersRequest.rejected]: addErrorMessage,
    [fetchFilteredOrdersRequest.rejected]: addErrorMessage,
    [fetchAddressesRequest.rejected]: addErrorMessage,
    [fetchSavedOrdersRequest.rejected]: addErrorMessage,
    [createNewAddressRequest.fulfilled]: addSuccessMessage,
    [createNewAddressRequest.rejected]: addErrorMessage,
    [deleteAddressRequest.fulfilled]: addSuccessMessage,
    [deleteAddressRequest.rejected]: addErrorMessage,
    [editAddressRequest.fulfilled]: addSuccessMessage,
    [editAddressRequest.rejected]: addErrorMessage,
    [updateContactRequest.fulfilled]: addSuccessMessage,
    [updateContactRequest.rejected]: addErrorMessage,
    [createNewOrderRequest.fulfilled]: addSuccessMessage,
    [createNewOrderRequest.rejected]: addErrorMessage,
    [calculateOrderPriceRequest.rejected]: addErrorMessage,
    [fetchInvoicesRequest.rejected]: addErrorMessage,
    [fetchInvoiceDownloadLinkRequest.rejected]: addErrorMessage,
    [createContactRequest.rejected]: addErrorMessage,
    [createContactRequest.fulfilled]: addSuccessMessage,
    [logInRequest.rejected]: (state, action) => {
      if (action.payload?.code === "auth/wrong-password") return;
      if (action.payload?.code === "auth/too-many-requests") {
        addErrorMessage(state, { payload: { message: "login.forms.loginForm.noAccessError" } });
      } else {
        const key = action.payload?.response?.data?.message || action.payload?.message;
        addErrorMessage(state, { payload: { message: key || "api:500.somethingWentWrong" } });
      }
    },
    [signOutRequest.rejected]: (state) => {
      addErrorMessage(state, { payload: { message: "api:500.somethingWentWrong" } });
    },
    [resetPasswordRequest.fulfilled]: (state) => {
      addSuccessMessage(state, { payload: { message: "login.forms.forgotPasswordForm.successfulResectionMessage" } });
    },
    [resetPasswordRequest.rejected]: (state, action) => {
      addErrorMessage(state, {
        payload: {
          message:
            action.payload?.code === "auth/user-not-found"
              ? "login.forms.forgotPasswordForm.failedResectionMessage"
              : "api:500.somethingWentWrong",
        },
      });
    },
    [reacceptContactTemrsRequest.fulfilled]: addSuccessMessage,
    [reacceptContactTemrsRequest.rejected]: addErrorMessage,
    [saveOrderRequest.fulfilled]: addSuccessMessage,
    [saveOrderRequest.rejected]: addErrorMessage,
    [deleteSavedOrderRequest.fulfilled]: addSuccessMessage,
    [deleteSavedOrderRequest.rejected]: addErrorMessage,
    [editSavedOrderRequest.fulfilled]: addSuccessMessage,
    [editSavedOrderRequest.rejected]: addErrorMessage,
    [parseCsvQuoteRequest.rejected]: addErrorMessage,
  },
});

export const { removeSuccessMessage, removeErrorMessage } = toastSlice.actions;

export const retrieveSuccessMessages = (state) => state.toast.successMessages;
export const retrieveErrorMessages = (state) => state.toast.errorMessages;

export default toastSlice.reducer;
