import { cx } from "@emotion/css";
import { ButtonBase } from "@mui/material";
import BoxLayout from "library/Layouts/BoxLayout/BoxLayout";
import StatusIndicator from "library/Molecules/StatusViews/StatusIndicator";
import { size } from "lodash";
import { getInvoiceStatusColor, getInvoiceStatusIcon } from "pages/Invoices/utils/helper";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { retrieveSelectedInvoice, setSelectedInvoice } from "store/features/invoice.store";
import InvoiceType from "types/models/Invoice";
import { prettifyStatusLabel } from "utility/helpers/general";
import commonStyles from "utility/ui/common";
import InvoiceBriefInfo from "./InvoiceBriefInfo/InvoiceBriefInfo";

const InvoiceItem = ({ invoice }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { id, created_at, due_at, price, lines, status } = invoice;

  const selectedInvoice = useSelector(retrieveSelectedInvoice);

  const isSelected = useMemo(() => selectedInvoice?.id === id, [selectedInvoice?.id, id]);

  const handleSelectInvoice = useCallback(() => dispatch(setSelectedInvoice(id)), [dispatch, id]);

  return (
    <BoxLayout
      className="my-3"
      componentClassName={cx(
        commonStyles.listItemContainer,
        "d-flex flex-wrap align-items-center justify-content-between gap-3 col-12 py-4 px-3 overflow-hidden"
      )}
      Component={(props) => <ButtonBase {...props} onClick={handleSelectInvoice} />}
      selected={isSelected}
      data-cy={`invoice-item-${id}`}
    >
      <InvoiceBriefInfo id={id} createdAt={created_at} dueAt={due_at} lines={size(lines)} price={price} />

      <StatusIndicator
        status={status}
        label={t([`utils.invoiceStatuses.${status}`, prettifyStatusLabel(status)])}
        renderColor={getInvoiceStatusColor}
        renderIcon={getInvoiceStatusIcon}
        data-cy={`invoice-item-chip-${id}-${status}`}
      />
    </BoxLayout>
  );
};

InvoiceItem.defaultProps = {
  invoice: {},
};

InvoiceItem.propTypes = {
  invoice: InvoiceType,
};

export default InvoiceItem;
