import { FormikProvider, useFormik } from "formik";
import useOrders from "pages/Dashboard/hooks/useOrders";
import OrderFiltersSkeleton from "pages/Dashboard/skeletons/OrderFiltersSkeleton/OrderFiltersSkeleton";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchFilteredOrdersRequest } from "store/requests/order.requests";
import OrderDateFilter from "./OrderDateFilter/OrderDateFilter";
import OrderSearchFilter from "./OrderSearchFilter/OrderSearchFilter";
import OrderSelectFilter from "./OrderSelectFilter/OrderSelectFilter";

const OrderFillters = () => {
  const dispatch = useDispatch();

  const [, , { isFirstLoading }] = useOrders();

  const filtersForm = useFormik({
    initialValues: { start: undefined, end: undefined, status: undefined, search: undefined },
    onSubmit: (filters) => dispatch(fetchFilteredOrdersRequest(filters)),
  });
  const { values: formValues, submitForm, dirty: isFormDirty } = filtersForm;

  useEffect(() => {
    if (!isFormDirty) return;
    submitForm();
  }, [isFormDirty, submitForm, formValues]);

  if (isFirstLoading) return <OrderFiltersSkeleton />;
  return (
    <FormikProvider value={filtersForm}>
      <div className="d-flex flex-wrap justify-content-between gap-2 py-md-2 my-4 pl-1 pr-3">
        <div className="d-flex col-lg-8 col-12 gap-2 px-0">
          <OrderSelectFilter />

          <OrderDateFilter />
        </div>

        <div className="col-lg-3 col-12 px-0">
          <OrderSearchFilter />
        </div>
      </div>
    </FormikProvider>
  );
};

export default OrderFillters;
