import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import useDeviceType from "hooks/useDeviceType";
import useOpenClose from "hooks/useOpenClose";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import Fab from "library/Atoms/FAB";
import PageHeaderLayout from "library/Layouts/PageHeaderLayout";
import SEO from "library/Molecules/SEO";
import useOrders from "pages/Dashboard/hooks/useOrders";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { retrieveContact } from "store/features/contact.store";
import { resetOrderFiltersAndPagination, retrieveOrdersStatus } from "store/features/order.store";
import { fetchOrdersRequest } from "store/requests/order.requests";
import OrdersList from "./components/OrdersList/OrdersList";
import OrdersMapView from "./components/OrdersMapView/OrdersMapView";
import DashboardLayout from "./layouts/DashboardLayout/DashboardLayout";

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useDeviceType();

  const contact = useSelector(retrieveContact);
  const { idle: ordersIdleStatus } = useSelector(retrieveOrdersStatus);

  const [, { onDeselectOrder }, { isOrderListEmpty, isOrderSelected, isFiltersApplied }] = useOrders();

  const [isMapOpen, openMap, closeMap] = useOpenClose(!isMobile);

  const isDashboardEmpty = useMemo(() => !isFiltersApplied && isOrderListEmpty, [isFiltersApplied, isOrderListEmpty]);

  const onMoveToNewOrder = useCallback(() => navigate("/new-order"), [navigate]);

  useEffect(() => {
    if (!ordersIdleStatus) return;
    dispatch(fetchOrdersRequest());
  }, [ordersIdleStatus, dispatch]);

  useEffect(() => {
    return () => onDeselectOrder();
  }, [onDeselectOrder]);

  useEffect(() => {
    return () => dispatch(resetOrderFiltersAndPagination());
  }, [dispatch]);

  return (
    <DashboardLayout
      pageHeaderSection={
        <PageHeaderLayout
          className="pt-3"
          contentClassName="flex-md-column flex-row align-items-md-start align-items-center"
          title={t("dashboard.pageTitle", { username: contact?.firstName })}
          subtitle={t("dashboard.pageDescription", isDashboardEmpty && { context: "empty" })}
          headerControls={
            <PrimaryButton className="w-100" label={t("common.buttons.newOrderBtn")} onClick={onMoveToNewOrder} />
          }
          data-cy="dashboard-header"
        />
      }
      listSection={!isDashboardEmpty && <OrdersList />}
      mapSection={!isDashboardEmpty && <OrdersMapView open={isMapOpen} />}
      fabSection={
        isMobile &&
        !isOrderSelected && (
          <Fab open onClick={isMapOpen ? closeMap : openMap} Icon={isMapOpen ? DnsOutlinedIcon : MapOutlinedIcon} />
        )
      }
    >
      <SEO title={t("seo.helmetTitle", { name: t("seo.pages.dashboard") })} />
    </DashboardLayout>
  );
};

export default Dashboard;
