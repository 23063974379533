import Pagination from "@mui/material/Pagination";
import ScrollLayout from "library/Layouts/ScrollLayout";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveInvoices, retrieveInvoicesPagination, retrieveInvoicesStatus } from "store/features/invoice.store";
import { fetchInvoicesRequest } from "store/requests/invoice.requests";
import InvoiceListSkeleton from "../../skeletons/InvoiceListSkeleton/InvoiceListSkeleton";
import InvoiceItem from "./InvoiceItem/InvoiceItem";

const InvoicesList = () => {
  const dispatch = useDispatch();

  const { loading: invoiceListLoading } = useSelector(retrieveInvoicesStatus);
  const invoices = useSelector(retrieveInvoices);
  const pagination = useSelector(retrieveInvoicesPagination);

  const handleChangePage = useCallback(
    (_, page) => pagination.active_page !== page && dispatch(fetchInvoicesRequest(page)),
    [pagination.active_page, dispatch]
  );

  return (
    <>
      <ScrollLayout className="d-flex flex-1 flex-column mt-4">
        {invoiceListLoading ? (
          <InvoiceListSkeleton />
        ) : (
          invoices.map((invoice) => <InvoiceItem key={invoice.id} invoice={invoice} />)
        )}
      </ScrollLayout>

      {pagination.total_pages > 1 && (
        <Pagination
          className="mx-auto pt-3"
          page={pagination.active_page}
          count={pagination.total_pages}
          onChange={handleChangePage}
          disabled={invoiceListLoading}
          color="primary"
          size="small"
        />
      )}
    </>
  );
};

export default InvoicesList;
