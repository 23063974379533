import { createAsyncThunk } from "@reduxjs/toolkit";
import PartnerApi from "utility/api";

export const fetchInvoicesRequest = createAsyncThunk(
  "invoices/fetchInvoicesRequest",
  async (page = 1, { rejectWithValue }) => {
    try {
      const result = await PartnerApi.invoices({ page });
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const fetchInvoiceDownloadLinkRequest = createAsyncThunk(
  "invoices/fetchInvoiceDownloadLinkRequest",
  async ({ invoiceId }, { rejectWithValue }) => {
    try {
      const result = await PartnerApi.getDownloadLink(invoiceId);
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
