import { cx } from "@emotion/css";
import Pagination from "@mui/material/Pagination";
import EmptyPageText from "library/Atoms/Text/EmptyPageText";
import { keys, size } from "lodash";
import useOrders from "pages/Dashboard/hooks/useOrders";
import OrderListLayout from "pages/Dashboard/layouts/OrderListLayout/OrderListLayout";
import OrdersListSkeleton from "pages/Dashboard/skeletons/OrdersListSkeleton/OrdersListSkeleton";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { retrieveOrdersPagination } from "store/features/order.store";
import { fetchOrdersRequest } from "store/requests/order.requests";
import { format } from "utility/date-fns";
import { calculatePackagesAmount } from "utility/helpers/orders";
import OrderFillters from "./OrderFillters/OrderFillters";
import OrderItem from "./OrderItem/OrderItem";

const OrdersList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const pagination = useSelector(retrieveOrdersPagination);

  const [{ ordersByMonth }, , { isOrderListEmpty, isListLoading, isFiltersApplied }] = useOrders();

  const handleChangePage = useCallback(
    (_, page) => pagination.active_page !== page && dispatch(fetchOrdersRequest(page)),
    [pagination.active_page, dispatch]
  );

  return (
    <OrderListLayout
      filtersSection={<OrderFillters />}
      listSection={
        !isListLoading &&
        keys(ordersByMonth).map((groupKey, index) => (
          <div key={groupKey} className={cx("pb-2", size(ordersByMonth) - 1 !== index && "mb-4")}>
            <div className="mb-2 text-color-grey400 text-fs-10">{format(new Date(groupKey), "MMM dd, yyyy")}</div>

            {ordersByMonth[groupKey].map((order) => (
              <OrderItem
                key={order.reference}
                reference={order.reference}
                startDate={order.startDate}
                endDate={order.endDate}
                packages={calculatePackagesAmount(order.drops)}
                status={order.status}
                express={order.isExpress}
              />
            ))}
          </div>
        ))
      }
      emptyListSection={
        isOrderListEmpty && isFiltersApplied && <EmptyPageText>{t("dashboard.emptyDeliveryListMessage")}</EmptyPageText>
      }
      paginationSection={
        pagination.total_pages > 1 && (
          <Pagination
            className="mx-auto pt-3"
            page={pagination.active_page}
            count={pagination.total_pages}
            onChange={handleChangePage}
            disabled={isListLoading}
            color="primary"
            size="small"
          />
        )
      }
      skeletonSection={isListLoading && <OrdersListSkeleton />}
    />
  );
};

export default OrdersList;
