import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSelectedOrder,
  retrieveGroppedOrders,
  retrieveOrderFilters,
  retrieveOrders,
  retrieveOrdersStatus,
  retrieveSelectedOrder,
  setSelectedOrder,
} from "store/features/order.store";

const useOrders = () => {
  const dispatch = useDispatch();

  const orders = useSelector(retrieveOrders);
  const ordersByMonth = useSelector(retrieveGroppedOrders);

  const selectedOrder = useSelector(retrieveSelectedOrder);

  const orderFilters = useSelector(retrieveOrderFilters);

  const { loading: ordersLoading, refetching: ordersRefetching } = useSelector(retrieveOrdersStatus);

  const isListLoading = useMemo(() => ordersLoading || ordersRefetching, [ordersLoading, ordersRefetching]);
  const isFiltersApplied = useMemo(() => !isEmpty(orderFilters), [orderFilters]);
  const isOrderListEmpty = useMemo(() => !isListLoading && isEmpty(orders), [isListLoading, orders]);
  const isOrderSelected = useMemo(() => !isEmpty(selectedOrder), [selectedOrder]);

  const onSelectOrder = useCallback((reference) => dispatch(setSelectedOrder(reference)), [dispatch]);

  const onDeselectOrder = useCallback(() => {
    if (!isOrderSelected) return;
    dispatch(resetSelectedOrder());
  }, [isOrderSelected, dispatch]);

  return [
    { orders, ordersByMonth, selectedOrder },
    { onSelectOrder, onDeselectOrder },
    { isListLoading, isOrderListEmpty, isOrderSelected, isFirstLoading: ordersLoading, isFiltersApplied },
  ];
};

export default useOrders;
