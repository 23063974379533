import useDeviceType from "hooks/useDeviceType";
import DetailsDrawer from "library/Organisms/DetailsDrawer";
import { isEmpty } from "lodash";
import { PropTypes } from "prop-types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { retrieveSelectedInvoice } from "store/features/invoice.store";
import styles from "./InvoiceDetailsDrawer.styles";

const InvoiceDetailsDrawer = ({ onClose, children, controlsSection }) => {
  const { isMobile } = useDeviceType();

  const selectedInvoice = useSelector(retrieveSelectedInvoice);

  const isOpen = useMemo(() => !isEmpty(selectedInvoice), [selectedInvoice]);

  return (
    <DetailsDrawer
      id={`invoice-details-drawer-${selectedInvoice?.id || ""}`}
      className={!isMobile && "position-sticky"}
      classNameInner={styles.drawer(isMobile)}
      onClose={onClose}
      contentInnerSection={children}
      contentControlsSection={isMobile && controlsSection}
      open={isOpen}
      focus={isOpen}
      openInner
      sticky
    />
  );
};

InvoiceDetailsDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  controlsSection: PropTypes.node.isRequired,
};

export default InvoiceDetailsDrawer;
